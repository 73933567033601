import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container } from "./Container"
import { SecondLevelHeader, ThirdLevelHeader } from "./SectionHeader"
import Deliverables from "./Deliverables"

const ContainerExtended = styled(Container)`
  /* top: -${props => props.theme.spacing[7]}; */
`

const Wrapper = styled.section`
  /* display: flex;
  flex-direction: column; */
  position: relative;
  margin-bottom: ${props => props.theme.spacing[10]};

  @media (min-width: 800px) {
    margin-bottom: ${props => props.theme.spacing[13]};
  }

  /* @media (min-width: 1100px) {
    flex-direction: row;
  } */
`

const Decoration = styled.div`
  position: absolute;
  transform: rotate(24deg);
  background: ${props => props.theme.colors.brand.gray};
  width: 90vw;
  right: -30vw;
  bottom: -20vw;
  height: 70vw;
  opacity: 0.3;
  z-index: -1;
  border-radius: 4px;

  @media (min-width: 600px) {
    height: 50vw;
  }

  @media (min-width: 1200px) {
    width: 70vw;
    height: 40vw;
    right: -20vw;
  }

  @media (min-width: 1400px) {
    width: 60vw;
    height: 30vw;
    right: -25vw;
  }
`

const TextSection = styled.div`
  /* flex: 0 0 60%; */
  margin-bottom: ${props => props.theme.spacing[7]};

  p {
    line-height: 1.4;
    font-size: 1.2rem;

    @media (min-width: 800px) {
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }

  @media (min-width: 800px) {
    max-width: 80%;
    margin-bottom: ${props => props.theme.spacing[10]};
  }

  @media (min-width: 1100px) {
    max-width: 60%;
    /* margin-bottom: 0; */
    /* padding-right: ${props => props.theme.spacing[7]}; */
  }
`

const ServicesSection = styled.div`
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
  }

  @media (min-width: 1100px) {
    margin-top: -${props => props.theme.spacing[10]};
    padding-left: ${props => props.theme.spacing[7]};
  }
`

const ServiceColumn = styled.div`
  flex: 0 0 50%;

  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    margin-top: ${props => (props.push ? props.theme.spacing[7] : 0)};
  }
`

const LeftColumn = styled(ServiceColumn)`
  padding-right: 0;

  @media (min-width: 800px) {
    padding-right: ${props => props.theme.spacing[4]};
  }
`

const RightColumn = styled(ServiceColumn)`
  padding-left: 0;

  @media (min-width: 800px) {
    padding-left: ${props => props.theme.spacing[4]};
  }
`

const SingleService = styled(Link)`
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.content};
  margin-bottom: ${props => props.theme.spacing[7]};
  padding: ${props => props.theme.spacing[7]};
  border-radius: 4px;
  height: auto;
  display: block;
  text-decoration: none;
  transform: translateY(0);
  transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  will-change: transform, box-shadow;
  position: relative;
  overflow-x: hidden;

  h3 {
    margin: 1rem 0;
    transition: color 0.25s ease;
    will-change: color;
  }

  p {
    line-height: 1.4;
    color: ${props => props.theme.colors.gray[6]};
    transition: color 0.25s ease;
    will-change: color;
    font-size: 1.2rem;
  }

  svg {
    position: absolute;
    bottom: ${props => props.theme.spacing[7]};
    right: ${props => props.theme.spacing[7]};
    stroke: ${props => props.theme.colors.gray[6]};
    transition: stroke 0.25s ease;
  }

  img {
    width: 60px;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-98%);
    transition: transform 0.25s ease;
    z-index: -1;

    &.purple {
      background-color: ${props => props.theme.colors.brand.purple};
    }

    &.green {
      background-color: ${props => props.theme.colors.brand.green};
    }

    &.orange {
      background-color: ${props => props.theme.colors.brand.orange};
    }

    &.blue {
      background-color: ${props => props.theme.colors.brand.blue};
    }
  }

  @media (min-width: 800px) {
    height: 340px;
  }

  @media (min-width: 1100px) {
    height: 400px;
  }

  @media (min-width: 1400px) {
    height: 320px;

    p {
      font-size: 1.2rem;
    }
  }

  :hover,
  :active,
  :focus {
    transform: translateY(-2px);
    box-shadow: ${props => props.theme.shadows.large};

    p,
    h3 {
      color: ${props => props.theme.colors.white};
    }

    svg {
      stroke: ${props => props.theme.colors.white};
    }

    > div {
      transform: translateX(0);
    }
  }
`

export const Services = () => {
  return (
    <ContainerExtended>
      <Wrapper>
        <Decoration />
        <TextSection>
          <SecondLevelHeader id="softwaresolutions">
            Software solutions
          </SecondLevelHeader>
          <p>
            Det digitala avtrycket som krävs i dagens samhälle ställer nya
            utmaningar där vi måste vara anpassningsbara, ta hänsyn till flera
            parametrar och där enbart tekniken är en del av leveransen.
          </p>
          <p>
            Våra erbjudanden täcker in helheten för era behov, går att kombinera
            och den know-how vi besitter utgör skillnaden från våra
            konkurrenter.
          </p>
        </TextSection>
        <Deliverables />
        {/* <ServicesSection>
          <LeftColumn>
            <SingleService to="/tjanster/identity">
              <ThirdLevelHeader>Identity</ThirdLevelHeader>
              <p>
                Digital identitetshantering för en säker och effektiv
                on-/off-boarding där varje användare har tillgång till rätt
                system vid varje givet tillfälle.
              </p>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
              <div className="purple"></div>
            </SingleService>
            <SingleService to="/tjanster/integration">
              <ThirdLevelHeader>Integration</ThirdLevelHeader>
              <p>
                Att standardisera informationsflöden och tjänster blir allt mer
                viktigt för alla företag men drivs oftast av olika anledningar.
                Vi hjälper er hitta den bästa lösningen för er.
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
              <div className="green"></div>
            </SingleService>
          </LeftColumn>
          <RightColumn push={true ? 1 : 0}>
            <SingleService to="/tjanster/digital-experience">
              <ThirdLevelHeader>Digital Experience</ThirdLevelHeader>
              <p>
                Oavsett ert behov i den digitala världen – intranät, extern
                webbplats eller app – så hjälper vi er att ta fram den lösning
                som passar bäst för er.
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
              <div className="orange"></div>
            </SingleService>
            <SingleService to="/tjanster/custom-solutions">
              <ThirdLevelHeader>Custom Solutions</ThirdLevelHeader>
              <p>
                Om ni är i behov av unika, skräddarsydda lösningar anpassade
                till er verksamhet hjälper vi er i hamn med vår erfarenhet och
                breda kompetens.
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
              <div className="blue"></div>
            </SingleService>
          </RightColumn>
        </ServicesSection> */}
      </Wrapper>
    </ContainerExtended>
  )
}
