import React from "react"
import styled from "styled-components"

import { ThirdLevelHeader } from "./SectionHeader"
import TagCloud from "./TagCloud"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

const SingleDeliverable = styled.div`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing[7]};
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.content};
  padding: 0 ${props => props.theme.spacing[4]} 0;
  padding-top: ${props => props.theme.spacing[4]};
  padding-bottom: ${props => props.theme.spacing[7]};
  border-radius: 4px;

  @media (min-width: 800px) {
    flex: 0 0 calc(50% - ${props => props.theme.spacing[4]});
  }

  /* @media (min-width: 1100px) {
    flex: 0 0 calc(33.3333333% - ${props => props.theme.spacing[4]});
  } */

  @media (min-width: 1600px) {
    flex: 0 0 calc(25% - ${props => props.theme.spacing[4]});
  }
`

const ShortText = styled.p`
  line-height: 1.4;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${props => props.theme.spacing[10]};

  @media (min-width: 800px) {
    font-size: 1.4rem;
    line-height: 1.6;
  }
`

const Deliverables = () => {
  return (
    <Wrapper>
      <SingleDeliverable>
        <ThirdLevelHeader center>Application Development</ThirdLevelHeader>
        <ShortText>Utveckling av framtidens digitala lösningar.</ShortText>
        <TagCloud
          tags={[
            "Digital formgivning",
            "CMS",
            "O365",
            "Headless CMS",
            "React",
            ".NET",
            "Progressive Web Apps (PWA)",
            "Java",
            "Tillgänglighetsanpassning",
          ]}
        />
      </SingleDeliverable>
      <SingleDeliverable>
        <ThirdLevelHeader center>Application Management</ThirdLevelHeader>
        <ShortText>
          Förvaltning och vidareutveckling av applikationer och system under
          dess livscykel.
        </ShortText>
        <TagCloud
          tags={[
            "pm3",
            "Scrum",
            "Jira",
            "ITIL",
            "Proaktivitet",
            "Prince2",
            "Livscykelhantering",
            "Servicedesk",
          ]}
        />
      </SingleDeliverable>
      <SingleDeliverable>
        <ThirdLevelHeader center>Identity</ThirdLevelHeader>
        <ShortText>
          Hantering av identiteter utifrån användarkonto, behörigheter,
          inloggning och åtkomst.
        </ShortText>
        <TagCloud
          tags={[
            "IAM/IAG",
            "PhenixID",
            "Azure AD",
            "MIM2016",
            "SkolFederation",
            "BankID",
            "MFA",
            "O365",
            "Federation",
            "Onboarding",
            "Sailpoint",
          ]}
        />
      </SingleDeliverable>
      <SingleDeliverable>
        <ThirdLevelHeader center>Digital Workplace</ThirdLevelHeader>
        <ShortText>Den digitala samarbetsytan för er verksamhet.</ShortText>
        <TagCloud
          tags={[
            "Dokumenthantering",
            "O365",
            "Teams",
            "Verksamhetsledning",
            "Processer",
            "Automatisering",
            "Kommunikation",
            "Samarbete",
            "Intranät",
          ]}
        />
      </SingleDeliverable>
    </Wrapper>
  )
}

export default Deliverables
