import React from "react"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import { Services } from "../components/Services"
import { Pitch } from "../components/Pitch"
import { PeopleHunt } from "../components/PeopleHunt"
import { Contact } from "../components/Contact"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Start" />
      <Hero />
      <Services />
      <Pitch />
      <PeopleHunt />
      <Contact />
    </Layout>
  )
}

export default IndexPage
