import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 0 -${props => props.theme.spacing[2]};
  margin-bottom: ${props => props.theme.spacing[10]};

  @media (min-width: 800px) {
    margin-bottom: ${props => props.theme.spacing[13]};
  }

  @media (min-width: 1000px) {
    max-width: 60%;
    margin: 0 auto ${props => props.theme.spacing[13]};
    justify-content: center;
  } */
`

const Tag = styled.span`
  flex: 0 0 auto;
  background-color: ${props => props.theme.colors.gray[1]};
  padding: ${props => props.theme.spacing[2]} ${props => props.theme.spacing[4]};
  border-radius: 25px;
  margin: 0 ${props => props.theme.spacing[2]}
    ${props => props.theme.spacing[4]};
  font-weight: bold;
  box-shadow: ${props => props.theme.shadows.light};
  font-size: 0.8rem;
`

const TagCloud = ({ tags }) => {
  return (
    <Wrapper>
      {tags && tags.map((item, index) => <Tag key={index}>{item}</Tag>)}
    </Wrapper>
  )
}

export default TagCloud
