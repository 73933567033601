import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { Container } from "./Container"
import { SecondLevelHeader } from "./SectionHeader"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "work_for_future.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage Tag="div" className={className} fluid={imageData} />
      )
    }}
  />
)

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing[12]};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadows.content};

  @media (min-width: 800px) {
    margin-bottom: ${props => props.theme.spacing[14]};
  }

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`

const LeftPanel = styled.div`
  flex: 0 0 40%;

  @media (min-width: 1000px) {
    padding-right: ${props => props.theme.spacing[7]};
  }
`

const LeftInner = styled(BackgroundSection)`
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 240px;
  height: 100%;
  border-radius: 4px 4px 0 0;

  @media (min-width: 800px) {
    min-height: 320px;
  }

  @media (min-width: 1000px) {
    min-height: 600px;
    border-radius: 4px 0 0 4px;
  }
`

const RightPanel = styled.div`
  flex: 0 0 60%;
  padding: ${props => props.theme.spacing[7]} ${props => props.theme.spacing[4]};

  p {
    line-height: 1.4;
    font-size: 1.2rem;

    @media (min-width: 800px) {
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }

  @media (min-width: 600px) {
    padding: ${props => props.theme.spacing[7]};
  }
`

const ReadMoreButton = styled(Link)`
  padding: ${props => props.theme.spacing[3]} ${props => props.theme.spacing[4]};
  background: ${props => props.theme.colors.brand.blue};
  display: inline-block;
  border-radius: 2px;
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  font-weight: bold;
  box-shadow: ${props => props.theme.shadows.medium};
  transform: translateY(0);
  cursor: pointer;
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  will-change: transform, box-shadow;

  @media (min-width: 1000px) {
    padding: ${props => props.theme.spacing[4]}
      ${props => props.theme.spacing[7]};
    font-size: 1.2rem;
  }

  :hover,
  :focus,
  :active {
    transform: translateY(-2px);
    box-shadow: ${props => props.theme.shadows.large};
  }
`

export const Pitch = () => {
  return (
    <Container>
      <Wrapper>
        <LeftPanel>
          <LeftInner />
        </LeftPanel>
        <RightPanel>
          <SecondLevelHeader>Hellre utvecklat än invecklat</SecondLevelHeader>
          <p>
            Mjukvaruindustrin är ändlös och i konstant förändring vilket
            utsätter företag för konstanta utmaningar: unika
            användarupplevelser, hög konkurrens, legacy-problem, komplicerade
            identitetsstrukturer eller korta implementationskrav, för att nämna
            några. Våra experter inspirerar till utveckling och gör det enkelt
            att växa med Advania.
          </p>
          <p>
            Vi driver den digitala evolutionen framåt. Vi ser möjligheten i att
            uppmana till förändring och tillsammans med våra kunder utforma
            framtidens teknik. Det handlar om spetskompetens kombinerat med
            drömmen om förbättring – att arbeta tillsammans med kunder och
            medarbetare som skapar en wow-upplevelse. Det är så vi gör skillnad!
          </p>
        </RightPanel>
      </Wrapper>
    </Container>
  )
}
